import { interceptor } from '@/api/interceptor'
import store from '../store/index'
export function initialization(){
	var userName = localStorage.getItem('userName')
	return interceptor.request({ url: `/initialization/${userName}` , method: 'GET', is_token : true, }).then(res => {
		let menuList = this.$common.menuList(res.data.menuList);
		res.data.user.menuList = res.data.menuList;
		if (res.data.user.isIndividual == 1) {
			res.data.user.alarmMessageList = res.data.alarmMessageList;
			res.data.user.is_read = false;
		}
		res.data.user.is_screen = menuList[1];
		store.commit('$uStore', {
			name: 'menuList',
			value: menuList[0]
		})
		store.commit('$uStore', {
			name: 'user_info',
			value: res.data.user
		})
		return Promise.resolve()
	})
}
export default{
	GetNumberOfDays( date1 , date2 ){//获得天数
	    //date1：开始日期，date2结束日期
	    var a1 = Date.parse(new Date(date1));
	    var a2 = Date.parse(new Date(date2));
	    var day = parseInt((a2-a1)/ (1000 * 60 * 60 * 24));//核心：时间戳相减，然后除以天数
	    return day
	},
	compare( property ) {
	  	return function (a, b) {
	   		var value1 = a[property];
	    	var value2 = b[property];
	    	return value1 - value2;
	 	}
	},
	objSort( data ){
		var arr=[];
		for(var key in data){
			data[key].k_id = key;
		    arr.push(data[key]);
		}
		arr = arr.sort(this.compare('sort'));
		var newData={}
		for(var i in arr){
		    var itemKey = arr[i].k_id;
		    newData[itemKey] = data[itemKey];
		}
		return newData;
	},
	menuList( menu ){
		var letfNav = {};
		let is_screen = false;
		menu.forEach( item => {
			if( item.name == "screen" ){
				is_screen = true;
			}
			if( item.parentId == 0 && item.name != "screen" ){
				letfNav[ 'id' + item.id ] = {
					title : item.title,
					id :  item.id,
					sort : item.sort,
					level : item.level,
					name :  item.name,
					children:[],
				}
			}
		});
		menu.forEach( item => {
			if( item.parentId != 0 ){
				item.path = `/${letfNav[ 'id' + item.parentId ].name}/${item.name}`;
				item.name = `${letfNav[ 'id' + item.parentId ].name}_${item.name}`;
				letfNav[ 'id' + item.parentId ].children.push( item );
			}
		});
		letfNav = this.objSort(letfNav);
		menu = [];
		for( let k in letfNav ){
			letfNav[k].children.sort( ( a , b ) => {
				return a.sort - b.sort;
			});
			menu.push( letfNav[k] )
		}
		return [ menu , is_screen ];
	},
	deepClone (obj) {
		// 对常见的“非”值，直接返回原来值
		if([null, undefined, NaN, false].includes(obj)) return obj;
	    if(typeof obj !== "object" && typeof obj !== 'function') {
			//原始类型直接返回
	        return obj;
	    }
	    var o = isArray(obj) ? [] : {};
	    for(let i in obj) {
	        if(obj.hasOwnProperty(i)){
	            o[i] = typeof obj[i] === "object" ? deepClone(obj[i]) : obj[i];
	        }
	    }
	    return o;
	},
	dateFormat(fmt, date) {
	    let ret;
	    const opt = {
	        "Y+": date.getFullYear().toString(),        // 年
	        "m+": (date.getMonth() + 1).toString(),     // 月
	        "d+": date.getDate().toString(),            // 日
	        "H+": date.getHours().toString(),           // 时
	        "M+": date.getMinutes().toString(),         // 分
	        "S+": date.getSeconds().toString()          // 秒
	        // 有其他格式化字符需求可以继续添加，必须转化成字符串
	    };
	    for (let k in opt) {
	        ret = new RegExp("(" + k + ")").exec(fmt);
	        if (ret) {
	            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
	        };
	    };
	    return fmt;
	},
	getStartTime( enddata , num ){
		enddata = new Date( enddata );
		var startDate = new Date( enddata );
		startDate.setDate( enddata.getDate() - 30 );
		var year = startDate.getFullYear();
		var month = startDate.getMonth() + 1;
		var day = startDate.getDate();
		return year + '-' + ( month < 10 ? '0' + month : month ) + '-' + ( day < 10 ? '0' + day : day );
	},
	//界面初始化时---账号区分处理
	init_surface(form,user){
		if( user.level == 7|| user.level == 2){
			form.monitoringPointIdList = (user.monitoringPointId).toString();
		}else{
			form.province = user.province;
			form.city = user.city;
			form.district = user.district;
		}
	}
}
