<template>
	<div id="app">
		<router-view v-if="isRouterAlive" />
	</div>
</template>

<script>
	export default {
		provide() {
			return {
				reload: this.reload
			}
		},
		data() {
			return {
				isRouterAlive: true
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false
				this.$nextTick(function() {
					this.isRouterAlive = true
				})
			}
		}
	}
</script>
<style lang="scss">
	@import "./assets/font-icon/iconfont.css";

	.layout_main_scrollbar .el-scrollbar__wrap {
		overflow-x: hidden;
	}
</style>
