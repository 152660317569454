let login_url = "/login";
let initialization_url = "/initialization";
//<===>系统管理

//用户管理
let user_list_url = "/user/list";
let user_delete_url =  "user/delete";
let user_add_url = "/user/add";
let user_update_url = "/user/update";
let user_getRole_url = "/user/getRole";
//用户管理结束

//角色管理
let role_getRole_url = "/role/getRole";
let role_list_url = "/role/list";
let role_add_url = "/role/add";
let role_delete_url = "/role/delete";
let role_update_url = "/role/update";
let role_setMenu_url = "/role/setMenu";
let role_setPermission_url = "/role/setPermission";
//角色管理结束

//菜单管理
let menu_getMenu_url = "/menu/getMenu";
let menu_get_url = "/menu/get";
let menu_list_url = "/menu/list";
let menu_getParentMenu_url = "/menu/getParentMenu";
let menu_add_url = "/menu/add";
let menu_delete_url = "/menu/delete";
let menu_update_url = "/menu/update";
let menu_sub_url = "/menu/sub";
//菜单管理结束

//权限管理
let permission_getPermission_url = "/permission/getPermission";
let permission_get_url = "/permission/get/";
let permission_list_url = "/permission/list";
let permission_add_url = "/permission/add";
let permission_delete_url = "/permission/delete";
let permission_update_url = "/permission/update";
//权限管理结束

//设备类型列表
let deviceType_url = "/deviceType/list";
let deviceTypeAdd = "/deviceType/add";
let deviceTypeDelete = "/deviceType/delete";
let deviceTypeUpdate = "/deviceType/update";

//按可信关阀

let closeValve = '/closeValve'
//家庭 
let homeList = '/home/list'
//家庭成员
let homeUser = '/wx/memberInfo'
//设备设置
let heaterStatus = '/heater/status'
//设备数据分析
let homePageCardList = '/homePage/cardList'
let homePageDeviceByModel = '/homePage/deviceByModel'

//<===>系统管理结束

const install = ( Vue, vm ) => {
	let login = ( params ) =>  vm.$interceptor.request({ url: login_url , method: 'POST',  data : params , is_token : false, });
	let initialization = ( params ) => vm.$interceptor.request({ url: `${initialization_url}/${params}` , method: 'GET', is_token : true, });
	//系统管理

	//用户管理
	let user_list = ( params ) => vm.$interceptor.request({ url: user_list_url , method: 'GET' , params : params , is_token : true });
	let user_add = ( params ) => vm.$interceptor.request({ url: user_add_url , method: 'POST' , data : params , is_token : true });
	let user_update = ( params ) => vm.$interceptor.request({ url: user_update_url , method: 'POST' , data : params , is_token : true });
	let user_getRole = ( params ) => vm.$interceptor.request({ url: user_getRole_url , method: 'GET' , params : params , is_token : true });
	let user_delete = ( params ) => vm.$interceptor.request({ url: user_delete_url , params : params , method: 'DELETE', is_token : true, });
	//用户管理结束

	//角色管理
	let role_getRole = () => vm.$interceptor.request({ url: role_getRole_url , method: 'GET' , is_token : true });
	let role_list = ( params ) => vm.$interceptor.request({ url: role_list_url , method: 'GET' , params : params , is_token : true });
	let role_add = ( params ) => vm.$interceptor.request({ url: role_add_url , method: 'POST' , data : params , is_token : true });
	let role_delete = ( params ) => vm.$interceptor.request({ url: role_delete_url , params : params , method: 'DELETE', is_token : true });
	let role_update = ( params ) => vm.$interceptor.request({ url: role_update_url , method: 'POST' , data : params , is_token : true });
	let role_setMenu = ( params ) => vm.$interceptor.request({ url: role_setMenu_url , method: 'GET' , params : params , is_token : true });
	let role_setPermission = ( params ) => vm.$interceptor.request({ url: role_setPermission_url , method: 'GET' , params : params , is_token : true });
	//角色管理结束

	//菜单管理
	let menu_getMenu = () => vm.$interceptor.request({ url: menu_getMenu_url , method: 'GET' , is_token : true });
	let menu_get = ( params ) => vm.$interceptor.request({ url: `${menu_get_url}/${params}` , method: 'GET', is_token : true, });
	let menu_list = ( params ) => vm.$interceptor.request({ url: menu_list_url , method: 'GET' , params : params , is_token : true });
	let menu_getParentMenu = ( params ) => vm.$interceptor.request({ url: menu_getParentMenu_url , method: 'GET' , data : params , is_token : true });
	let menu_add = ( params ) => vm.$interceptor.request({ url: menu_add_url , method: 'POST' , data : params , is_token : true });
	let menu_delete = ( params ) => vm.$interceptor.request({ url: menu_delete_url , params : params , method: 'DELETE', is_token : true });
	let menu_update = ( params ) => vm.$interceptor.request({ url: menu_update_url , method: 'POST' , data : params , is_token : true });
	let menu_sub = ( params ) => vm.$interceptor.request({ url: menu_sub_url , method: 'GET' , params : params , is_token : true });
	//菜单管理结束

	//设备类型
	let deviceType = (params) => vm.$interceptor.request({ url: deviceType_url , method: 'GET' , params : params , is_token : true });
	let deviceType_add = (params) => vm.$interceptor.request({ url: deviceTypeAdd , method: 'POST' , data : params , is_token : true });
	let deviceType_delete = ( params ) => vm.$interceptor.request({ url: deviceTypeDelete , params : params , method: 'DELETE', is_token : true });
	let deviceType_update = (params) => vm.$interceptor.request({ url: deviceTypeUpdate , method: 'POST' , data : params , is_token : true });

	//权限管理
	let permission_getPermission = () => vm.$interceptor.request({ url: permission_getPermission_url , method: 'GET' , is_token : true });
	let permission_get = ( params ) => vm.$interceptor.request({ url: `${permission_get_url}${params}` , method: 'GET', is_token : true, });
	let permission_list = ( params ) => vm.$interceptor.request({ url: permission_list_url , method: 'GET' , params : params , is_token : true });
	let permission_add = ( params ) => vm.$interceptor.request({ url: permission_add_url , method: 'POST' , data : params , is_token : true });
	let permission_delete = ( params ) => vm.$interceptor.request({ url: permission_delete_url , params : params , method: 'DELETE', is_token : true });
	let permission_update = ( params ) => vm.$interceptor.request({ url: permission_update_url , method: 'POST' , data : params , is_token : true });
	//权限管理结束

	//设备管理
	let device_list = ( params ) => vm.$interceptor.request({ url: "/device/list" , method: 'GET' , params : params , is_token : true });
	let device_add = ( params ) => vm.$interceptor.request({ url: "/device/add" , method: 'POST' , data : params , is_token : true });
	let device_update = ( params ) => vm.$interceptor.request({ url: "/device/update" , method: 'POST' , data : params , is_token : true });
	let device_delete = ( params ) => vm.$interceptor.request({ url: "/device/delete" , method: 'DELETE' , params : params , is_token : true });
	let device_getTypeList = ( params ) => vm.$interceptor.request({ url: "/device/getTypeList" , method: 'GET' , params , is_token : true });
	let device_downloadTemplate = () => vm.$interceptor.request({ url: "/device/downloadTemplate" , responseType:'blob' , method: 'GET' , is_token : true });
	let device_importDevice = ( params ) => vm.$interceptor.request({ url: "/device/importDevice" , method: 'POST' , data : params , is_token : true });
	
	//设备管理结束

	//报警管理
	let alarm_export = ( params ) => vm.$interceptor.request({ url: "/alarm/export" , responseType:'blob' , method: 'POST' , params , is_token : true });
	let alarm_list = ( params ) => vm.$interceptor.request({ url: "/alarm/list" , method: 'GET' , params : params , is_token : true });
	let messageForm_list = ( params ) => vm.$interceptor.request({ url: "/alarm/messageForm/list" , method: 'GET' , params : params , is_token : true });
	
	//报警管理结束

	//地图
	let map_list = ( params ) => vm.$interceptor.request({ url: "/map/list" , method: 'GET' , params : params , is_token : true });
	let map_history = ( params ) => vm.$interceptor.request({ url: "/map/history" , method: 'GET' , params : params , is_token : true });
	let map_stateOfStatistical = ( params ) => vm.$interceptor.request({ url: "/map/stateOfStatistical" , method: 'GET' , params : params , is_token : true });
	//地图结束

	//报表
	let report_alarm = ( params ) => vm.$interceptor.request({ url: "/report/alarm" , method: 'GET' , params : params , is_token : true });

	//首页
	let screen_list = ( params ) => vm.$interceptor.request({ url: "/screen/list" , method: 'GET' , params : params , is_token : true });
	let screen_excessiveRanking = ( params ) => vm.$interceptor.request({ url: "/screen/excessiveRanking" , method: 'GET' , params : params , is_token : true });
	let screen_emissionData = ( params ) => vm.$interceptor.request({ url: "/screen/emissionData" , method: 'GET' , params : params , is_token : true });
	let screen_alarmInfo = ( params ) => vm.$interceptor.request({ url: "/screen/alarmInfo" , method: 'GET' , params : params , is_token : true });

	//设备上报数据列表
	let deviceData_list = ( params ) => vm.$interceptor.request({ url: "/deviceData/list" , method: 'GET' , params : params , is_token : true });
	let deviceData_export = ( params ) => vm.$interceptor.request({ url: "/deviceData/export" , responseType:'blob' , method: 'POST' , params : params , is_token : true });
	
	//指令下发列表
	let command_list = ( params ) => vm.$interceptor.request({ url: "/command/list" , method: 'GET' , params : params , is_token : true });
	let command_export = ( params ) => vm.$interceptor.request({ url: "/command/export" , responseType:'blob' , method: 'POST' , params : params , is_token : true });

	let setFunc_Rsq = ( params ) => vm.$interceptor.request( {url:"/userFunc/setFunc" ,  method: 'POST' , params :params , is_token : true} );

	//数据面板
	let screen_deviceGeneral = ( params ) => vm.$interceptor.request({ url: "/screen/deviceGeneral" , method: 'GET' , params : params , is_token : true });
	let screen_yesterdayAlarm = ( params ) => vm.$interceptor.request({ url: "/screen/alarmInfo" , method: 'GET' , params : params , is_token : true });
	let screen_alarmNum = ( params ) => vm.$interceptor.request({ url: "/screen/alarmNum" , method: 'GET' , params : params , is_token : true });
	let screen_deviceStatus = ( params ) => vm.$interceptor.request({ url: "/screen/deviceStatus" , method: 'GET' , params : params , is_token : true });
	let screen_userGeneral = ( params ) => vm.$interceptor.request({ url: "/screen/userGeneral" , method: 'GET' , params : params , is_token : true });
	let bindDevice_bindList = ( params ) => vm.$interceptor.request({ url: "/bindDevice/bindList" , method: 'GET' , params : params , is_token : true });
	let bindDevice_removeBind = ( params ) => vm.$interceptor.request({ url: "/bindDevice/removeBind?id=" + params.id + '&homeId=' + params.homeId + '&typeCode=' + params.typeCode , method: 'DELETE' , is_token : true });
	let bindDevice_bind = ( params ) => vm.$interceptor.request({ url: "/bindDevice/bind" , method: 'GET' , params : params , is_token : true });
	
	//按可信关阀
	let akxCloseValve = ( params ) => vm.$interceptor.request({ url: closeValve , method: 'GET' , params : params , is_token : true });
	let home_list = ( params ) => vm.$interceptor.request({ url: homeList , method: 'GET' , params : params , is_token : true });
	let home_user = ( params ) => vm.$interceptor.request({ url: homeUser , method: 'GET' , params : params , is_token : true });
	let screenMapList = ( params ) => vm.$interceptor.request({ url: '/screen/mapList' , method: 'GET' , params : params , is_token : true });
	let screenHomeNum = ( params ) => vm.$interceptor.request({ url: '/screen/homeNum' , method: 'GET' , params : params , is_token : true });
	
	//设备
	let deviceFuncSetHistory_list = ( params ) => vm.$interceptor.request({ url: '/userFunc/getFuncHistory' , method: 'GET' , params : params , is_token : true });
	let monitoringPoint_uploadPicture = ( params ) => vm.$interceptor.request({ url: "/uploadPicture" , method: 'POST' , data : params , is_token : true });
	
	//设备图片
	let deviceModelAdd = ( params ) => vm.$interceptor.request({ url: "/deviceModel/add" , method: 'POST' , data : params , is_token : true });	
	let deviceModelUpdate = ( params ) => vm.$interceptor.request({ url: "/deviceModel/update" , method: 'POST' , data : params , is_token : true });
	let deviceModelGetList = ( params ) => vm.$interceptor.request({ url: "/deviceModel/getList" , method: 'GET' , params : params , is_token : true });
	let deviceModelDelete = ( params ) => vm.$interceptor.request({ url: "/deviceModel/delete" , method: 'DELETE' , params : params , is_token : true });
	
	//新热水器状态查询
	let heater_status = ( params ) => vm.$interceptor.request({ url: heaterStatus , method: 'GET' , params : params , is_token : true });
	//老热水器状态查询
	let oldHeaterstatus = ( params ) => vm.$interceptor.request({ url: "/heater/oldHeaterstatus" , method: 'GET' , params : params , is_token : true });
	//吸油烟机当前状态查询
	let rangHooSstatus = ( params ) => vm.$interceptor.request({ url: "/rangHood/status" , method: 'GET' , params : params , is_token : true });
	//灶具当前状态查询
	let stoveStatus = ( params ) => vm.$interceptor.request({ url: "/stove/status" , method: 'GET' , params : params , is_token : true });
	//壁挂炉当前状态查询
	let wallHeaterStatus = ( params ) => vm.$interceptor.request({ url: "/wallHeater/status" , method: 'GET' , params : params , is_token : true });
	//设备对应操作功能查询
	let userFuncGetSelectDict = ( params ) => vm.$interceptor.request({ url: "/userFunc/getSelectDict" , method: 'GET' , params : params , is_token : true });
	//设备数据分析
	let homePage_CardList = ( params ) => vm.$interceptor.request({ url: homePageCardList , method: 'GET' , params : params , is_token : true });
	let homePage_DeviceByModel = ( params ) => vm.$interceptor.request({ url: homePageDeviceByModel , method: 'GET' , params : params , is_token : true });
	//系统管理结束
	Vue.prototype.$api = {
		login , initialization ,
		user_list , user_add , user_update , user_getRole , user_delete, 
		role_getRole , role_list , role_add , role_delete , role_update , role_setMenu , role_setPermission,
		menu_getMenu , menu_get , menu_list , menu_getParentMenu , menu_add , menu_delete , menu_update , menu_sub,
		permission_getPermission , permission_get , permission_list , permission_add , permission_delete , permission_update,
		device_list,device_add,device_update,device_delete,
		map_list,map_history,map_stateOfStatistical,
		screen_list,screen_excessiveRanking,screen_emissionData,
		deviceType,deviceType_add,deviceType_delete,deviceType_update,device_getTypeList,device_downloadTemplate,device_importDevice,
		deviceData_list,deviceData_export,command_list,command_export,alarm_list,alarm_export,report_alarm,
		screen_deviceGeneral,screen_yesterdayAlarm,screen_alarmNum,screen_deviceStatus,screen_userGeneral,bindDevice_bindList,bindDevice_removeBind,bindDevice_bind,
		akxCloseValve,
		home_list,
		home_user,
		screenMapList,
		screenHomeNum,
		heater_status,
		deviceFuncSetHistory_list,
		monitoringPoint_uploadPicture,
		deviceModelAdd,
		deviceModelUpdate,
		deviceModelGetList,
		deviceModelDelete,
		oldHeaterstatus,
		setFunc_Rsq,
		messageForm_list,
		rangHooSstatus,
		stoveStatus,
		wallHeaterStatus,
		userFuncGetSelectDict,
		homePage_CardList,
		homePage_DeviceByModel
	};
}
export default install;