import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import { initialization } from '@/common/common'

Vue.use( Router );

/* Layout */
import Layout from '@/layout/index.vue';

export const constantRoutes = [
	{
		path: '*',
		component: () => import('@/views/redirect/index'),
		hidden: true
	},
	{
		path: '/login',
		component: () => import('@/views/login/index'),
		hidden: true,
		name : 'login'
	},
	{
		path: '/screen',
		component: () => import('@/views/screen/index'),
		hidden: true,
		name : '大屏驾驶舱',
		meta : {
			keepAlive: false
		}
	},
	{
		path: '/system',
		component: Layout,
		children: [
			//设备数据列表
			{
				path: '/deviceDataList/deviceData',
				component: () => import('@/views/deviceDataList/deviceData'),
				name: 'deviceDataList_deviceData',
				meta: {
					title: '设备数据列表',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			//指令下发列表
			{
				path: '/commandSendList/commandSend',
				component: () => import('@/views/commandSendList/commandSend'),
				name: 'commandSendList_commandSend',
				meta: {
					title: '指令下发查看',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			//报警消息列表
			{
				path: '/alarmList/alarm',
				component: () => import('@/views/alarmList/alarm'),
				name: 'alarmList_alarm',
				meta: {
					title: '报警记录',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			//报警消息列表
			{
				path: '/alarmList/alarmMessage',
				component: () => import('@/views/alarmList/alarmMessage'),
				name: 'alarmList_alarmMessage',
				meta: {
					title: '报警消息',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			//设备管理
			{
				path: '/deviceManage/device',
				component: () => import('@/views/deviceManage/index'),
				name: 'deviceManage_device',
				meta: {
					title: '设备管理',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			//设备类型管理
			{
				path: '/systemManage/device',
				component: () => import('@/views/systemManage/device'),
				name: 'systemManage_device',
				meta: {
					title: '设备管理',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			{
				path: '/systemManage/userManage',
				component: () => import('@/views/systemManage/userManage'),
				name: 'systemManage_userManage',
				meta: {
					title: '后台用户管理',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			{
				path: '/userList/userInfo',
				component: () => import('@/views/userList/userInfo'),
				name: 'userList_userInfo',
				meta: {
					title: '用户信息',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			{
				path: '/systemManage/roleManage',
				component: () => import('@/views/systemManage/roleManage'),
				name: 'systemManage_roleManage',
				meta: {
					title: '角色管理',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			{
				path: '/systemManage/permissionManage',
				component: () => import('@/views/systemManage/permissionManage'),
				name: 'systemManage_permissionManage',
				meta: {
					title: '权限管理',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			{
				path: '/systemManage/menuManage',
				component: () => import('@/views/systemManage/menuManage'),
				name: 'systemManage_menuManage',
				meta: {
					title: '菜单管理',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			{
				path: '/deviceControl/deviceDataControl',
				component: () => import('@/views/deviceControl/deviceDataControl'),
				name: 'deviceControl_deviceDataControl',
				meta: {
					title: '设备监测数据',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			{
				path: '/deviceControl/typeImage',
				component: () => import('@/views/deviceControl/typeImage'),
				name: 'deviceControl_typeImage',
				meta: {
					title: '设备类型图片',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			{
				path: '/deviceControl/deviceDataAnalysis',
				component: () => import('@/views/deviceControl/deviceDataAnalysis'),
				name: 'deviceControl_deviceDataAnalysis',
				meta: {
					title: '设备数据分析',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			{
				path: '/deviceDataList/deviceFuncsetHistory',
				component: () => import('@/views/deviceDataList/deviceFuncsetHistory'),
				name: 'deviceDataList_deviceFuncsetHistory',
				meta: {
					title: '设备功能设置记录',
					icon: 'dashboard',
					affix: true,
					keepAlive: true
				}
			},
			//寒门无弱种
			//生当开泰来
			//莫叫无出处
			//自把远名留
		]
	},
];
export const asyncRoutes_children = [

]
export const asyncRoutes = []

const createRouter = () => new Router({
    scrollBehavior: () => ({
        y: 0
    }),
    routes: constantRoutes
});

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher
}
router.beforeEach( async ( to , from , next ) => {
	 //不就是这里面 这里要改一下 需要先判断用户信息存不存在 没得话获取一下
	if( to.name === 'login' || to.path == '/' ) {
	    next();
	    return;
	}
	if( store.state.user_info == "" ){
		if( localStorage.getItem('userName') && localStorage.getItem('userName') !== '' ){
			try{
				await initialization()
			}catch(e){
				next( '/login' );
				return;
			}
		}else{
			next( '/login' );
			return;
		}
	}
	//
	//判断是否有权限访问大屏
	if( store.state.user_info.is_screen && to.path == '/screen' ){
		// console.log( to.path )
		// next({ ...to, replace: true });
		next();
		return;
	}
	//判断是否有权限访问大屏
	if( localStorage.getItem("is_screen") && to.path == '/screen' ){
		// console.log( to.path )
		// next({ ...to, replace: true });
		next();
		return;
	}
	//判断当前要跳转得页面是否在用户登录信息中
	let menuList = store.state.user_info.menuList.filter( item => {
		return item.path == to.path;
	});
	if( menuList.length > 0 ){
		next();
	}else{
		next( false );
	}
});


// const fullName = computed(() => `${userInfo.name}是个好人`);
// function changeUserName() {
// 	userInfo.name = '李四';
// }

export default router;
