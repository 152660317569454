<template>
	<div class="layout_container" :style="{ 'padding-left' : left_fold ? '80px' : '255px' }">
		<div class="layout_header">
			<div style="position: relative;width: 160px;">
				<div style="font-size: 18px;font-weight: bold;color:#ffffff;width:100px;letter-spacing:2px">
					CHIFFO
				</div>
			</div>
			<div style="position: absolute;top: 16px;left: 190px;font-weight: bold;color:#ffffff">
				<p>前锋燃气安全卫士</p>
				<p style="font-size: 12px;">Gas Safety System</p>
			</div>
			<img src="../assets/sercurity.png" style="position: absolute;top: 13px;left: 320px;" width="40px" alt="">
			<div class="header_right">
				<div @click="show_msg" v-if=" user_info.isIndividual == 1 " class="massage mg_r">
					<el-badge :hidden=" user_info.is_read || user_info.alarmMessageList.length == 0 " :value=" user_info.alarmMessageList.length " class="item">
						<i class="el-icon-bell"></i>
					</el-badge>
				</div>
				<el-dropdown @command="handleCommand" :hide-on-click="false">
					<div class="user_info">
						<img style="width: 45px;height: 45rpx;border-radius: 23px;" src="../assets/touxiang.jpg" >
					</div>
					<el-dropdown-menu slot="dropdown" style="height: 20px;">
						<el-dropdown-item command="out" style="color: #6375E5;line-height: 20px;">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<div class="info" style="margin-right: 40px;">
					<div style="font-size: 16px;font-weight: bold;color: #ffffff;">{{ this.user_info.userName }}, 您好，欢迎登录！</div>
				</div>
			</div>
		</div>
		<div class="layout_aside" :class=" left_fold ? 'layout_aside_fold' : '' ">
			<div class="logo">
				<div class="userMenu" v-show=" !left_fold ">
					<span style="display: inline-block;border: 4px solid #ffffff;border-radius: 30px;height: 47px;"><img style="width: 45px;height: 45rpx;border-radius: 23px;" src="../assets/userHeader.png" ></span>
					<span style="position: absolute;line-height: 0px;top:35px;left:95px">{{ this.user_info.userName }}</span>
				</div>
				<div class="header_left">
					<i class="el-icon-s-fold" @click=" left_fold = !left_fold "></i>
				</div>
			</div>
			<el-scrollbar class="layout_main_scrollbar" style="height: calc( 100% - 66px );">
				<!-- <left-nav :collapse="left_fold" @changeUrl="changeThisUrl"/> -->
				<left-nav :collapse="left_fold"/>
			</el-scrollbar>
		</div>
		<div class="layout_main">
			<el-tabs v-model="editableTabsValue" type="card" :closable="editableTabs.length == 1 ? false : true " @tab-remove="removeTab" @tab-click="changeTab">
				<el-tab-pane v-for="(item, index) in editableTabs" :key="item.name" :label="item.title" :name="item.name" >
				</el-tab-pane>
			</el-tabs>
			<el-scrollbar class=" layout_main_scrollbar " style="height: calc( 100% - 66px );">
				<transition name="fade-transform" mode="out-in">
					<keep-alive :include="keepAliveList">
						<router-view class="pd" :key="key" ref="pageContainer"/>
					</keep-alive>
				</transition>
			</el-scrollbar>
		</div>
	</div>
</template>

<script>
	import leftNav from './left-nav.vue';
	export default{
		created(){
			
		},
		data(){
			return {
				left_fold : false,
				dialogVisible : false,
				editableTabsValue: "",
				editableTabs: [],
				tabIndex: '',
				changePageUrl:"",
				keepAliveList: this.$store.getters.getKeepAliveList,
			}
		},
		components:{
			leftNav,
		},
		computed: {
			getKeepAliveList () {
					return this.$store.getters.getKeepAliveList
			},
			key() {
				return this.$route.path
			}
		},
		watch: {
			"$route.path": {
				handler() {
					for( var i = 0 ; i < this.editableTabs.length ; i++ ){
						if( this.editableTabs[i].content == this.$route.path ){
							this.editableTabsValue = this.editableTabs[i].name
						}
					}
					this.addTab()
				},
				immediate: true
			},
			getKeepAliveList (n, o) {
				this.keepAliveList = n
			}
		},
		methods:{
			changeTab( targetName ){
				this.editableTabsValue = targetName.name;
				for( var i = 0 ; i < this.editableTabs.length ; i++ ){
					if( this.editableTabs[i].name == targetName.name ){
						this.$router.replace({path:this.editableTabs[i].content})
					}
				}
			},
			addTab( targetName ) {
				for( var i = 0 ; i < this.menuList.length ; i++ ){
					for( var k = 0 ; k < this.menuList[i].children.length ; k++ ){
						if( this.$route.path == this.menuList[i].children[k].path ){
							let newTabName = this.menuList[i].children[k].title;
							for( var s = 0 ; s < this.editableTabs.length ; s++ ){
								if( this.editableTabs[s].name == newTabName ){
									return
								}
							}
							this.editableTabs.push({
								title: newTabName,
								name: newTabName,
								content: this.menuList[i].children[k].path
							});
							this.editableTabsValue = newTabName;
						}
					}
				}
			},
			removeTab( targetName ) {
				let tabs = this.editableTabs;
				let activeName = this.editableTabsValue;
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.name === targetName) {
							let nextTab = tabs[index + 1] || tabs[index - 1];
							if (nextTab) {
								activeName = nextTab.name;
							}
						}
					});
				}
				const {cache, keys} = this.$refs.pageContainer.$options.parent;
				// 遍历删除，或指定删除路由缓存
				keys.forEach(key => {
					delete cache[key];// 删除指定路由缓存
				})
				// 将值清空
				keys.splice(0, keys.length - 1);
				this.editableTabsValue = activeName;
				this.editableTabs = tabs.filter(tab => tab.name !== targetName);
				this.editableTabs.forEach( ( item , i ) => {
					if (item.name === this.editableTabsValue) {
						this.$router.replace({path:item.content})
					}
				});
			},
			nav( path ){
				this.$router.push({
					path,
				});
				this.handleClose();
			},
			handleClose(){
				this.dialogVisible = false;
			},
			show_msg(){
				if( this.user_info.alarmMessageList.length == 0 ){
					this.$message({
						type: 'success',
						message: "暂无未读消息"
					});
					return;
				}
				this.dialogVisible = true;
				if( !this.user_info.is_read ){
					this.alarm_read();
				}
			},
			alarm_read(){
				let alarmMessageIdList = [];
				this.user_info.alarmMessageList.forEach( item => {
					alarmMessageIdList.push( item.id );
				});
				this.$api.alarm_read( { alarmMessageIdList : alarmMessageIdList.toString() } ).then( res => {
					if( res.code == 200 ){
						let user_info = this.user_info;
						user_info.is_read = true;
						this.$vuex( 'user_info' , user_info );
					}
				});
			},
			handleCommand( command ){
				if( command == "out" ){
					this.$router.replace({ path : '/login' });
					this.$vuex( 'user_info' , "" );
				}
			}
		},
		filters:{
			level( value ){
				if( value == 1 ){
					return "超级管理员"
				}else if( value == 2 ){
					return "特定用户"
				}else if( value == 3 ){
					return "国家级"
				}else if( value == 4 ){
					return "省级"
				}else if( value == 5 ){
					return "市级"
				}else if( value == 6 ){
					return "区县"
				}else if( value == 7 ){
					return "个体用户"
				}else if( value == 8 ){
					return "维护人员"
				}else{
					return ""
				}
			}
		},
	}
</script>

<style lang="scss">
	// .v-enter,.v-leave-to{
	// 	transform:translateX(150px);
	// 	opacity: 0;
	// }
	// .v-enter-active,.v-leave-active{
	// 	transition: all ease .5s; 
	// }
	
	$nav_transition : .3s all;
	.layout_main{
		height: 100%;
		width: 100%;
	}
	.layout_container{
		height: 100%;
		position: relative;
		padding-top: 65px;
		padding-left: 255px;
		box-sizing: border-box;
		transition: $nav_transition;
		.layout_header{
			height: 65px;
			left: 0;
			top: 0;
			box-shadow: 0 1px 4px rgba( 0 ,21 , 41 , .2 );
			width: 100%;
			position: absolute;
			padding-left: 105px;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			z-index: 99;
			background: linear-gradient(135deg, #6b6be4 , #15d8f1);
			.header_right{
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-right: 15px;
				.user_info{
					cursor: pointer;
				}
				.info{
					margin-left: 10px;
					font-size: 12px;
				}
				.massage{
					cursor: pointer;
					margin-right: 20px;
					position: relative;
					i{
						font-size: 22px;
						color: #515a6e;
					}
				}
			}
		}
		.layout_aside{
			transition: $nav_transition;
			top: 65px;
			left: 0;
			width: 255px;
			height: calc( 100% - 66px );;
			background: linear-gradient(135deg, #6b6be4 , #15d8f1);
			position: absolute;
			box-shadow: 2px 0 6px rgba(0,21,41,.35);
			.logo{
				color: #fff;
				height: 65px;
				line-height: 65px;
				user-select: none;
				font-weight: bold;
				border-bottom: 1px solid #9d9abd;
				text-align: center;
				position: relative;
				display: flex;
				justify-content: space-around;
				.userMenu{
					padding-top: 5px;
				}
				.header_left{
					align-items: center;
					i.el-icon-s-fold{
						margin: 0 15px;
						cursor: pointer;
						font-size: 18px;
						transition: $nav_transition;
					}
				}
			}
		}
		.layout_aside.layout_aside_fold{
			width: 80px;
			.nav_item{
				.title{
					justify-content: center;
				}
			}
		}
	}
</style>
