import { mapState } from 'vuex'
import store from "./index.js"

let $uStoreKey = [];
try{
	$uStoreKey = store.state ? Object.keys(store.state) : [];
}catch(e){
	
}

const mixin = {
	created() {
		this.$vuex = (name, value) => {
			this.$store.commit('$uStore', {
				name,value
			})
		}
	},
	computed: {
		...mapState($uStoreKey)
	}
}
export default mixin;