<template>
	<el-menu style="width: 100%;" :default-active="$route.path" :collapse="collapse" :router="true">
		<el-menu-item index="/screen" v-if="is_screen == 1">
		    <i class="el-icon-menu" style="color: #ffffff;"></i>
		    <span slot="title" style="color: #ffffff;">大屏驾驶舱</span>
		</el-menu-item>
		<template slot="title">
			<i class="el-icon-location"></i>
			<span>导航一</span>
		</template>
		<el-submenu :index=" `${i}` " popper-class="left_nav_submenu" v-for=" ( item , i ) in menuList " :key="i" >
			<template slot="title">
				<i :class=" `icon-${item.name}`  " class="iconfont" style="color: #ffffff;"></i>
				<span slot="title">{{item.title}}</span>
			</template>
			<el-menu-item :index=" item.path " :route="item" v-for=" ( item , n ) in item.children " :key="n" >{{item.title}}</el-menu-item>
		</el-submenu>
	</el-menu>
</template>

<script>
	export default{
		mounted(){
		},
		data(){
			return {
				is_screen : localStorage.getItem('is_screen')
			}
		},
		methods:{
			to_screen(){
				this.$router.replace({ path: "/screen" });
			},
		},
		props:{
			collapse : {
				default: false,
				type : Boolean
			}
		}
	}
</script>

<style lang="scss">
	.layout_main_scrollbar{
		.el-submenu{
			.el-menu{
				border-right: none;
				background-color:  #696EE5;
			}
		}
		.el-tooltip{
			text-align: center;
		}
		.el-menu{
			background-color: transparent;
			border-right: none;
			// background-color: #101117;
			.el-submenu__title{
				// background-color: #696EE5;
				color: #FFFFFF;
				&:hover i,&:hover span{
					color: #FFFFFF;
				}
				&:hover{
					// background-color: #696EE5; 
					background: linear-gradient( 90deg, #696EE5 , #15d8f1);
				}
				.el-submenu__icon-arrow{
					// color: ;
				}
			}
			.el-menu-item{
				color: hsla(0,0%,100%,.7);
				&:hover,&:focus{
					color: #ffff;
					background-color: transparent;
				}
			}
			.el-menu-item.is-active{
				// background: linear-gradient(45deg, #4a15f1 , #696EE5);
				background: #5156d2;
				color: #FFFFFF;
			}
			.el-submenu.is-opened{
				.el-submenu__title{
					i,span{
						color: #FFFFFF;
					}
				}
			}
		}
		.el-menu--collapse{
			.el-submenu__title{
				text-align: center;
			}
		}
	}
	.left_nav_submenu{
		.el-menu{
			background-color: #191A23;
			.el-menu-item{
				color: hsla(0,0%,100%,.7);
				&:hover{
					color: #ffff;
					background-color: transparent;
				}
			}
			.el-menu-item.is-active{
				background-color: #2D8CF0;
				color: #FFFFFF;
			}
		}
	}
</style>
