import Vue from 'vue';
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入路由
import VueRouter from 'vue-router';
//引入vuex
import Vuex from 'vuex';

import router from './router'
//引入vuex
import store from './store';

//引入通用方法
import common from "./common/common.js";

//引入百度地图
import BaiduMap from 'vue-baidu-map';

import './style/style.scss' // global css

import App from './App.vue';

Vue.prototype.$common = common;
//边框素材
import dataV from '@jiaminghi/data-view'

import { message } from './rewrite/message';


Vue.use(dataV)

Vue.use( ElementUI );
Vue.use( VueRouter );
Vue.use( Vuex );
Vue.use( BaiduMap , {
	ak: 'IO5nbnGLk1HrKOLIxvpcDZQDnGhPjmvi'
})
import storeMixin from './store/$u.mixin.js';
Vue.mixin( storeMixin )
Vue.prototype.$message = message;
const app = new Vue({
	router,
	store,
	el: '#app',
	render: h => h( App )
});

// http拦截器，将此部分放在new Vue()和app.$mount()之间，才能App.vue中正常使用
import httpInterceptor from './api/interceptor.js';
Vue.use( httpInterceptor , app );
// http接口API抽离，免于写url或者一些固定的参数
import httpApi from './api/api.js';
Vue.use( httpApi , app );

app.$mount();
